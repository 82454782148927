import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ExamResponse = () => {
  const location = useLocation();

  console.log(location.state, "data from backend");

  const downloadPdf = () => {
    if (!location.state || !location.state.content) {
      alert("No content available to generate PDF.");
      return;
    }

    const doc = new jsPDF("p", "pt", "a4");
    let startY = 60;
    let pageNumber = 1;

    // Title and Message
    doc.setFontSize(18);
    doc.text("Question Paper", 40, 30);

    const content = location.state.content;

    // Iterate through each question and answer
    Object.keys(content).forEach((key, index) => {
      const item = content[key];
      const question = item.Question.trim();
      const answer = item.Answer.trim();

      // Check if content exceeds page height; add new page if necessary
      const pageHeight = doc.internal.pageSize.height;
      const lineHeight = 15;
      const questionLines = doc.splitTextToSize(question, 500);
      const answerLines = doc.splitTextToSize(answer, 500);
      const totalLines = questionLines.length + answerLines.length + 4; // Question, Answer, and padding lines

      if (startY + totalLines * lineHeight > pageHeight - 40) {
        doc.addPage();
        startY = 60;
        pageNumber++;
      }

      // Add question number and question
      doc.setFontSize(12);
      doc.setFont("times", "bold");
      doc.text(`Question ${index + 1}`, 40, startY);
      startY += lineHeight;

      doc.setFontSize(12);
      doc.setFont("times", "normal");
      const splitQuestion = doc.splitTextToSize(question, 500);
      doc.text(splitQuestion, 40, startY);
      startY += splitQuestion.length * lineHeight + 10; // Adjust startY for answer
    });

    // Save the PDF
    doc.save("exam_response.pdf");
  };

  return (
    <Container>
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <h1 className="text-center mb-4">Question Paper</h1>
          <div className="text-center mb-4">
            <Button variant="primary" onClick={downloadPdf}>
              Download PDF
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExamResponse;
