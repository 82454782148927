import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Card, Button } from "react-bootstrap";

const Response = () => {
  const location = useLocation();
  const [parsedData, setParsedData] = useState([]);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [visibleAnswers, setVisibleAnswers] = useState({});
  const [userAttempts, setUserAttempts] = useState("");

  useEffect(() => {
    if (location.state && location.state.content) {
      setParsedData(location.state.content);
      console.log(location.state.content, "data");
    } else {
      setParsedData([]);
    }
  }, [location.state]);

  const toggleQuestion = (index) => {
    if (expandedQuestion === index) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(index);
    }
  };

  const showAnswer = (index) => {
    setVisibleAnswers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleAttemptChange = (e, index) => {
    const { value } = e.target;
    setUserAttempts((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      answers: userAttempts,
      qa_data: parsedData,
    };

    console.log("Payload:", payload);
  };

  return (
    <Container>
      <h1 className="mt-4 mb-4">API Response</h1>
      {parsedData.length > 0 ? (
        parsedData.map((item, index) => (
          <Card key={index} className="mb-4">
            <Card.Body>
              {expandedQuestion === index ? (
                <div id={`question-${index}`} className="mb-3">
                  <Card.Text>
                    <strong>Question:</strong> {item.Question}
                  </Card.Text>
                  <div className="mt-3">
                    <label htmlFor={`attempt-${index}`}>
                      <strong>Your Attempt:</strong>
                    </label>
                    <textarea
                      id={`attempt-${index}`}
                      className="form-control"
                      placeholder="Type your answer here"
                      onChange={(e) => handleAttemptChange(e, index)}
                      value={userAttempts[index] || ""}
                      rows="6"
                    />
                  </div>
                  <br />
                  <Button variant="primary" onClick={() => showAnswer(index)}>
                    {visibleAnswers[index] ? "Hide Answer" : "Show Answer"}
                  </Button>
                  {visibleAnswers[index] && (
                    <Card.Text className="mt-2">
                      <strong>Answer:</strong> {item.Answer}
                    </Card.Text>
                  )}
                </div>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => toggleQuestion(index)}
                  aria-controls={`question-${index}`}
                  aria-expanded={expandedQuestion === index ? "true" : "false"}
                  className="mb-2"
                >
                  Question {index + 1}
                </Button>
              )}
            </Card.Body>
          </Card>
        ))
      ) : (
        <Card>
          <Card.Body>
            <Card.Text>No data available.</Card.Text>
          </Card.Body>
        </Card>
      )}
      <Container className="d-flex justify-content-center">
        <Button variant="success" onClick={handleSubmit} className="m-2">
          Submit
        </Button>
      </Container>
    </Container>
  );
};

export default Response;
