import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const MainForm = () => {
    const [selectedForm, setSelectedForm] = useState('');
    const navigate = useNavigate();

    const handleFormSelect = (event) => {
        setSelectedForm(event.target.value);
    };

    const openForm = () => {
        if (selectedForm) {
            navigate(selectedForm);
        } else {
            alert('Please select a form');
        }
    };

    return (
        <Container>
            <h1 className="mt-5">Select a Form</h1>
            <Form id="mainForm">
                <Form.Group controlId="formSelect">
                    <Form.Label>Choose a form:</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedForm}
                        onChange={handleFormSelect}
                    >
                        <option value="">--Select a form--</option>
                        <option value="/topic-wise-form">Topic Wise Assessment</option>
                        <option value="/exam-form">Exam paper</option>                       
                    </Form.Control>
                </Form.Group>
                <br/>
                <Button variant="primary" onClick={openForm}>
                    Open Form
                </Button>
            </Form>
        </Container>
    );
};

export default MainForm;

