import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ExamForm = () => {
  const [classData, setClassData] = useState({});
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [formData, setFormData] = useState({
    totalQuestions: "",
    trueFalse: "",
    mcq: "",
    fillInTheBlanks: "",
    short: "",
    long: "",
  });
  const [error, setError] = useState(null);
  const [savedSelections, setSavedSelections] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchClassData();
  }, []);

  const fetchClassData = async () => {
    try {
      const response = await fetch("/api/class-data");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setClassData(data);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setSelectedSubject("");
    setSelectedChapter("");
    setSelectedTopic("");
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setSelectedChapter("");
    setSelectedTopic("");
  };

  const handleChapterChange = (e) => {
    const selectedChapters = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedChapter(selectedChapters);
    setSelectedTopic("");
  };

  const handleTopicChange = (e) => {
    const selectedTopics = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedTopic(selectedTopics);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    validateForm();
  };

  const renderOptions = (options) => {
    return Object.keys(options).map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  const renderSubjects = () => {
    if (!selectedClass) return null;
    return renderOptions(classData[selectedClass]);
  };

  const renderChapters = () => {
    if (!selectedClass || !selectedSubject) return null;

    const subjects = classData[selectedClass];

    if (!subjects || !Array.isArray(subjects[selectedSubject])) return null;

    return subjects[selectedSubject].map((chapterObj, index) => {
      const chapterName = Object.keys(chapterObj)[0];
      return (
        <option key={index} value={chapterName}>
          {chapterName}
        </option>
      );
    });
  };

  const renderTopics = () => {
    if (!selectedClass || !selectedSubject || selectedChapter.length === 0)
      return null;

    const topicsSet = new Set();

    selectedChapter.forEach((chapter) => {
      const chapters = classData[selectedClass][selectedSubject];
      const chapterData = chapters.find(
        (chap) => Object.keys(chap)[0] === chapter
      );
      if (chapterData) {
        const chapterTopics = chapterData[chapter];
        if (Array.isArray(chapterTopics)) {
          chapterTopics.forEach((topic) => topicsSet.add(topic));
        }
      }
    });

    const topicsArray = Array.from(topicsSet);

    return topicsArray.map((topic, index) => (
      <option key={index} value={topic}>
        {topic}
      </option>
    ));
  };

  const validateForm = () => {
    const { totalQuestions, trueFalse, mcq, fillInTheBlanks, short, long } =
      formData;

    const totalIndividualQuestions = [
      parseInt(trueFalse) || 0,
      parseInt(mcq) || 0,
      parseInt(fillInTheBlanks) || 0,
      parseInt(short) || 0,
      parseInt(long) || 0,
    ].reduce((acc, val) => acc + val, 0);

    if (parseInt(totalQuestions) < totalIndividualQuestions) {
      setError(
        "Total number of selected questions exceeds the total number of questions."
      );
    } else {
      setError(null);
    }
  };

  // Function to handle saving selections

  const handleSaveSelection = () => {
    if (selectedChapter.length > 0 && selectedTopic.length > 0) {
      const updatedSelections = { ...savedSelections };

      selectedChapter.forEach((chapter) => {
        updatedSelections[chapter] = [...selectedTopic]; // Make a copy of selectedTopic
      });

      setSavedSelections(updatedSelections);

      sessionStorage.setItem(
        "selectedChaptersTopics",
        JSON.stringify(updatedSelections)
      );

      console.log("Saved selections:", updatedSelections);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const selectedChapter = Object.keys(savedSelections);
    const selectedTopic = Object.values(savedSelections).flat();

    const selectedChaptersTopics = {};
    selectedChapter.forEach((chapter) => {
      const topics = renderTopics(chapter);
      selectedChaptersTopics[chapter] = topics.filter((topic) =>
        selectedTopic.includes(topic)
      );
    });

    const payload = {
      class: selectedClass,
      subject: selectedSubject,
      chapters: selectedChapter,
      topics: selectedTopic,
      total_questions: formData.totalQuestions,
      true_false: formData.trueFalse,
      mcq: formData.mcq,
      fill_in_the_blanks: formData.fillInTheBlanks,
      short: formData.short,
      long: formData.long,
      chapters_topics: savedSelections,
    };
    console.log("Payload:", payload);

    try {
      const response = await fetch("/api/submit-exam-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Form submitted successfully. Response:", responseData);

      navigate("/response-exam", { state: responseData });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container mt-5">
      <Form onSubmit={handleSubmit}>
        <h1> Create Exam Form </h1>{" "}
        {error && <Alert variant="danger"> {error} </Alert>}{" "}
        <Row>
          <Col>
            <Form.Group controlId="formClass">
              <Form.Label> Class </Form.Label>{" "}
              <Form.Control
                as="select"
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option value=""> Select Class </option>{" "}
                {Object.keys(classData).map((className) => (
                  <option key={className} value={className}>
                    {className}
                  </option>
                ))}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
          <Col>
            <Form.Group controlId="formSubject">
              <Form.Label> Subject </Form.Label>{" "}
              <Form.Control
                as="select"
                value={selectedSubject}
                onChange={handleSubjectChange}
                disabled={!selectedClass}
              >
                <option value=""> Select Subject </option> {renderSubjects()}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col>
            <Form.Group controlId="formChapter">
              <Form.Label> Chapter </Form.Label>{" "}
              <Form.Control
                as="select"
                multiple
                value={selectedChapter}
                onChange={handleChapterChange}
                disabled={!selectedSubject}
              >
                <option value=""> Select Chapter </option>
                {renderChapters()}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
          <Col>
            <Form.Group controlId="formTopic">
              <Form.Label> Topic </Form.Label>{" "}
              <Form.Control
                as="select"
                multiple
                value={selectedTopic}
                onChange={handleTopicChange}
                disabled={!selectedChapter.length}
              >
                <option value=""> Select Topic </option> {renderTopics()}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col>
            <Form.Group controlId="formTotalQuestions">
              <Form.Label> Total Questions </Form.Label>{" "}
              <Form.Control
                as="select"
                name="totalQuestions"
                value={formData.totalQuestions}
                onChange={handleInputChange}
                style={{ borderColor: error ? "red" : "" }}
              >
                <option value=""> Choose number of questions </option>{" "}
                {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
              {error && (
                <Form.Text style={{ color: "red" }}> {error} </Form.Text>
              )}{" "}
            </Form.Group>{" "}
          </Col>{" "}
          <Col>
            <Form.Group controlId="formTrueFalse">
              <Form.Label> True / False </Form.Label>{" "}
              <Form.Control
                as="select"
                name="trueFalse"
                value={formData.trueFalse}
                onChange={handleInputChange}
              >
                <option value="">
                  {" "}
                  Choose number of True / False questions{" "}
                </option>{" "}
                {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col>
            <Form.Group controlId="formMCQ">
              <Form.Label> MCQ </Form.Label>{" "}
              <Form.Control
                as="select"
                name="mcq"
                value={formData.mcq}
                onChange={handleInputChange}
              >
                <option value=""> Choose number of MCQ questions </option>{" "}
                {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
          <Col>
            <Form.Group controlId="formFillInTheBlanks">
              <Form.Label> Fill in the Blanks </Form.Label>{" "}
              <Form.Control
                as="select"
                name="fillInTheBlanks"
                value={formData.fillInTheBlanks}
                onChange={handleInputChange}
              >
                <option value=""> Choose number of Fill in the Blanks </option>{" "}
                {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col>
            <Form.Group controlId="formShort">
              <Form.Label> Short Questions </Form.Label>{" "}
              <Form.Control
                as="select"
                name="short"
                value={formData.short}
                onChange={handleInputChange}
              >
                <option value=""> Choose number of Short questions </option>{" "}
                {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
          <Col>
            <Form.Group controlId="formLong">
              <Form.Label> Long Questions </Form.Label>{" "}
              <Form.Control
                as="select"
                name="long"
                value={formData.long}
                onChange={handleInputChange}
              >
                <option value=""> Choose number of Long questions </option>{" "}
                {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {" "}
                    {num}{" "}
                  </option>
                ))}{" "}
              </Form.Control>{" "}
            </Form.Group>{" "}
          </Col>{" "}
        </Row>
        <Row></Row>
        <br />
        <Button variant="primary" onClick={handleSaveSelection}>
          Save Selection{" "}
        </Button>{" "}
        <Button
          variant="primary"
          type="submit"
          disabled={
            !selectedClass ||
            !selectedSubject ||
            !selectedChapter.length ||
            !selectedTopic.length ||
            !formData.totalQuestions ||
            !formData.trueFalse ||
            !formData.mcq ||
            !formData.fillInTheBlanks ||
            !formData.short ||
            !formData.long
          }
        >
          Submit{" "}
        </Button>{" "}
      </Form>{" "}
    </div>
  );
};

export default ExamForm;
