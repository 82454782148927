import FormData from "./components/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Response from "./components/Response";
import MainForm from "./components/MainForm";
import ExamForm from "./components/ExamForm";
import ExamResponse from "./components/ExamResponse";

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainForm />} />{" "}
          <Route path="/topic-wise-form" element={<FormData />} />{" "}
          <Route path="/exam-form" element={<ExamForm />} />{" "}
          <Route path="/response-topic" element={<Response />} />{" "}
          <Route path="/response-exam" element={<ExamResponse />} />{" "}
        </Routes>{" "}
      </Router>{" "}
    </>
  );
}

export default App;
