import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function FormData() {
  const [classData, setClassData] = useState({});
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [totalQuestion, setTotalQuestion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchClassData();
  }, []);

  const fetchClassData = async () => {
    try {
      const response = await fetch("/api/class-data");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setClassData(data);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setSelectedClass(selectedClass);
    setSelectedSubject("");
    setSelectedChapter("");
    setSelectedTopic("");
  };

  const handleSubjectChange = (e) => {
    const selectedSubject = e.target.value;
    setSelectedSubject(selectedSubject);
    setSelectedChapter("");
    setSelectedTopic("");
  };

  const handleChapterChange = (e) => {
    const selectedChapter = e.target.value;
    setSelectedChapter(selectedChapter);
    setSelectedTopic("");
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    setSelectedLevel(selectedLevel);
  };

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);
  };

  const handleTopicChange = (e) => {
    const selectedTopic = e.target.value;
    setSelectedTopic(selectedTopic);
  };

  const handleTotalQuestion = (e) => {
    setTotalQuestion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      class: selectedClass,
      subject: selectedSubject,
      chapter: selectedChapter,
      topic: selectedTopic,
      type: selectedType,
      level: selectedLevel,
      totalQuestion: totalQuestion,
    };
    console.log("payload", payload);
    try {
      const response = await fetch(
        "/api/submit-topic-wise-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      navigate("/response-topic", { state: responseData });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderOptions = (options) => {
    return Object.keys(options).map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  const renderSubjects = () => {
    if (!selectedClass) return null;
    return renderOptions(classData[selectedClass]);
  };

  const renderChapters = () => {
    if (!selectedClass || !selectedSubject) return null;
    const subjects = classData[selectedClass];
    const subjectData = subjects[selectedSubject];
    if (!subjectData) return null;

    return subjectData.map((chapterObject, index) => {
      const chapterName = Object.keys(chapterObject)[0]; // Get the chapter name
      return (
        <option key={index} value={chapterName}>
          {chapterName}
        </option>
      );
    });
  };

  const renderTopics = () => {
    if (!selectedClass || !selectedSubject || !selectedChapter) return null;

    const subjects = classData[selectedClass];
    if (!subjects) return null;

    const subjectData = subjects[selectedSubject];
    if (!subjectData) return null;

    const chapterObject = subjectData.find(
      (chapterObj) => Object.keys(chapterObj)[0] === selectedChapter
    );
    if (!chapterObject) return null;

    const topics = chapterObject[selectedChapter];
    if (!topics || !Array.isArray(topics)) return null; // Ensure topics is an array

    return topics.map((topic, index) => (
      <option key={index} value={topic}>
        {topic}
      </option>
    ));
  };

  return (
    <div className="container mt-5">
      <Form onSubmit={handleSubmit}>
        <h1>Create Topic Wise Assessment</h1>
        <Row>
          <Col>
            <Form.Group controlId="formClass">
              <Form.Label>Class</Form.Label>
              <Form.Control
                as="select"
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option value="">Select Class</option>
                {Object.keys(classData).map((className) => (
                  <option key={className} value={className}>
                    {className}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                as="select"
                value={selectedSubject}
                onChange={handleSubjectChange}
                disabled={!selectedClass}
              >
                <option value="">Select Subject</option>
                {renderSubjects()}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formChapter">
              <Form.Label>Chapter</Form.Label>
              <Form.Control
                as="select"
                value={selectedChapter}
                onChange={handleChapterChange}
                disabled={!selectedSubject}
              >
                <option value="">Select Chapter</option>
                {renderChapters()}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formTopic">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                as="select"
                value={selectedTopic}
                onChange={handleTopicChange}
                disabled={!selectedChapter}
              >
                <option value="">Select Topic</option>
                {renderTopics()}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formLevel">
              <Form.Label>Level</Form.Label>
              <Form.Control
                as="select"
                value={selectedLevel}
                onChange={handleLevelChange}
              >
                <option value="">Select Hardness Level</option>
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedType}
                onChange={handleTypeChange}
              >
                <option value="">Select Type</option>
                <option value="Subjective">Subjective</option>
                <option value="Objective">Objective</option>
                <option value="Reading">Reading</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formType">
              <Form.Label>Total Number of Questions</Form.Label>
              <Form.Control
                type="number"
                name="totalQuestion"
                value={totalQuestion}
                onChange={handleTotalQuestion}
                placeholder="Enter Total Number of Questions"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Button
          variant="primary"
          type="submit"
          disabled={!selectedTopic || !selectedType || !selectedLevel}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default FormData;
